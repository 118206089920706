
const Constants = Object.freeze({
  MESSAGE: {
    NOT_SUPPORTED: 'Not supported.',
  },
  POST: {
    TYPE: 'lh-mol-viewer',
    COMPLETE: 'complete',
    ERROR: 'error',
  },
  EVENT: {
    MESSAGE: 'message',
    FAILURE: 'failure',
  },
  IFRAME: {
    ELEMENT_ID: 'iframe_jsmol',
  },
});

export default Constants;
