import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Constants from '../utils/Constants';

const MolViewer = ({ molContent, viewType, fnCb, fnInit }) => {
  if (!/^file|^mol/.test(viewType)) return <span>{Constants.MESSAGE}</span>;

  const [isIframeLoaded, setIframeLoaded] = useState(() => {
    return false;
  });

  useEffect(() => {
    function handleMessage(event) {
      // Check the origin of the message
      if (event.origin !== window.location.origin) {
        return;
      }

      const { type, data } = event.data;

      if (type === Constants.POST.TYPE && data === Constants.POST.COMPLETE) {
        // The data sent with postMessage is stored in event.data
        fnCb();
      }
    }

    // Add the message listener
    window.addEventListener(Constants.EVENT.MESSAGE, handleMessage);

    // Remove the message listener when the component unmounts
    return () => {
      window.removeEventListener(Constants.EVENT.MESSAGE, handleMessage);
    };
  }, []);

  useEffect(() => {
    if (!isIframeLoaded) return;

    const iframe = document.getElementById(Constants.IFRAME.ELEMENT_ID);
    let runScript = 'set echo top left;font echo 18 arial italic;echo loading file...processing...this might take a while if the file contains a complex structure.;refresh;';
    if (/^file/.test(viewType)) {
      runScript += ` load async ${molContent}; echo; zoom 45;`;
      iframe.contentWindow.postMessage({ type: 'lh-mol-viewer', data: runScript }, '*');
    } else if (/^mol/.test(viewType)) {
      molContent.text().then(text => {
        runScript += `load DATA "mol_example" ${text} end "mol_example";show data; zoom 45;`;
        iframe.contentWindow.postMessage({ type: Constants.POST.TYPE, data: runScript }, '*');
      });
    }
    fnInit();
  }, [viewType, isIframeLoaded]);

  return (
    <div className="structure-frame-container">
      <iframe
        id={Constants.IFRAME.ELEMENT_ID}
        src={`/jsmol/index.htm?_USE=HTML5`}
        title="Mol Viewer Web"
        className="frame-div"
        onLoad={() => setIframeLoaded(true)}
      />
    </div>
  );
};

MolViewer.propTypes = { molContent: PropTypes.object.isRequired, viewType: PropTypes.string, fnCb: PropTypes.func, fnInit: PropTypes.func };
MolViewer.defaultProps = { viewType: 'mol', fnCb: () => {}, fnInit: () => {} };

export default MolViewer;
